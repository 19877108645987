import React from "react";
import Layout from "./layout";
import { addLocaleData } from "react-intl";
import messages from "data/messages/my";
import "intl/locale-data/jsonp/my";

const my = require("react-intl/locale-data/my");
addLocaleData(my);

const layout = (props) => <Layout {...props} i18nMessages={messages} />;

export default layout;
